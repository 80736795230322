
import 'element-ui/lib/theme-chalk/index.css'
// import Element from 'element-ui'
// import Vue from 'vue'
// Vue.use(Element)
import Vue from 'vue'
import Message from 'element-ui/lib/message'
import MessageBox from 'element-ui/lib/message-box'
import Loading from 'element-ui/lib/loading'
import Button from 'element-ui/lib/button'
import Radio from 'element-ui/lib/radio'
import Checkbox from 'element-ui/lib/checkbox'
import Link from 'element-ui/lib/link'
import Input from 'element-ui/lib/input'
import RadioGroup from 'element-ui/lib/radio-group'
import Image from 'element-ui/lib/image'
import Tabs from 'element-ui/lib/tabs'
import TabPane from 'element-ui/lib/tab-pane'
import Container from 'element-ui/lib/container'
import Header from 'element-ui/lib/header'
import Footer from 'element-ui/lib/footer'
import Main from 'element-ui/lib/main'

Vue.use(Loading)
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$msgbox = MessageBox
Vue.use(Button)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Link)
Vue.use(Input)
Vue.use(RadioGroup)
Vue.use(Image)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Container)
Vue.use(Header)
Vue.use(Footer)
Vue.use(Main)