import { store } from './store'
import { MessageProxy } from './MessageProxy'
localStorage.setItem("sessionID", '');
export class Config {
    static get serverUrl() {
      return window.config.serverUrl;
    }
    
    static get imageUrl() {
        if (window.config.imageUrl != undefined && window.config.imageUrl != "") {
            return window.config.imageUrl;
        }
        return location.origin + "/film/";
    }

    static get imageProUrl() {
        if (window.config.imageProUrl != undefined && window.config.imageProUrl != "") {
            return window.config.imageProUrl;
        }
        return location.origin + "/image/";
    }

    static get image3dUrl() {
        if (window.config.image3dUrl != undefined && window.config.image3dUrl != "") {
            return window.config.image3dUrl;
        }
        return location.origin + "/image3d/";
    }

  static getMainHost() {
    let key  = `mh_${Math.random()}`;
    let keyR = new RegExp( `(^|;)\\s*${key}=12345` );
    let expiredTime = new Date( 0 );
    let domain = document.domain;
    let domainList = domain.split( '.' );
    let urlItems   = [];
    // 主域名一定会有两部分组成
    urlItems.unshift( domainList.pop() );
    // 慢慢从后往前测试
    while( domainList.length ) {
      urlItems.unshift( domainList.pop() );
      let mainHost = urlItems.join( '.' );
      let cookie   = `${key}=${12345};domain=.${mainHost}`;
      document.cookie = cookie;
      //如果cookie存在，则说明域名合法
      if ( keyR.test( document.cookie ) ) {
        document.cookie = `${cookie};expires=${expiredTime}`;
        return mainHost;
      }
    }
  }
  static getUrlParam(str) {
    if (!str) {
      if (window.location.hash.indexOf('?') > -1) {
        str = window.location.hash.split('?')
          str = '?' + str[1]
      } else {
        str = window.location.href.replace(new RegExp(location.origin + location.pathname), '').replace(new RegExp(location.hash), '')
      }
    }
    str = decodeURI(str, "utf-8")
    let obj = {}
    if(!str) {
      return { str, obj }
    }
    // console.log('str', str)
    str = str.split('?')[1]
    if(!str) {
      return { str, obj }
    }

    let arr = str.split('&')
    if(!arr.length) {
      return { str, obj }
    }
    arr.forEach(el => {
      el = el.split('=')
      obj[el[0]] = el[1]
    });

    if (obj.param) {
      const val = obj.param
      delete obj.param
      val.split(',').forEach(el => {
        el = el.split(':')
        obj[el[0]] = el[1]
      })
    }
    return { str, obj }
  }
  static resetUrlParam (obj, arr) {
    arr = arr || []
    let newArr = []
    for (let key in obj) {
      if (arr.indexOf(key) === -1) {
        newArr.push(key + '=' + obj[key])
      }
    }
    return newArr.join('&') ? '?' + newArr.join('&') : ''
  }
  static isWxClient() {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      return true;
    }
    return false;
  }
  /*
  static setBaseUrl() {
    store.originalUrl = location.href;
    store.hostUrl = location.origin + "/";
    let pagePath = location.pathname.split('/')
    pagePath.pop()
    pagePath = pagePath.join('/')
    store.baseUrl = location.origin + pagePath + "/"
    store.imageUrl = store.baseUrl + "images/";
    let pos = location.href.lastIndexOf("/");
    pos = location.href.indexOf("?");
    store.actualUrl = location.href.substring(0, pos);
    }
  */

  static async getWxOpenId() {
    // 微信环境 获取openid -> userid
    if (Config.isWxClient() && !store.userId) {
      const urlParam = Config.getUrlParam().obj
      const search = Config.getUrlParam().str
      store.wx.openid = urlParam.openid ? urlParam.openid : ''
      
      if (store.wx.openid) {
        localStorage.setItem("wx", JSON.stringify({openid: store.wx.openid, appid: store.wx.appid}));
      } else {
        let wx = localStorage.getItem("wx")
        wx = wx ? JSON.parse(wx) : {}
        store.wx.openid = wx.appid === store.wx.appid ? wx.openid : ''
      }
      // 获取openid
      if (!store.wx.openid) {
        if (!urlParam.code) {
          let response_type = 'code'
          let scope = 'snsapi_base' // snsapi_userinfo 用户需要点同意
          let redirect_uri = window.location.protocol + '//code.efilm.top/code.html?returnurl=' + location.href
          redirect_uri = encodeURIComponent(redirect_uri)
          let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${store.wx.appid}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}&state=1#wechat_redirect`
          url = url.replace(/#/g, '%23')
          // console.log('url***', url)
          // console.log('redirect_uri***', redirect_uri)
          window.location = url
          return
        } else {
          // console.log('code--', urlParam.code)
          await MessageProxy.getWXaccess_token({ code: urlParam.code }).then((result) => {
            if (true == result.hasError) {
                // alert(result.errorText);
                this.$message.error(result.errorText);
              return;
            }
            if (result.data != null && result.data.code != '200') {
                // alert(result.data.message);
                this.$message.error(result.data.message);
              return;
            }
            if (result.data.data) {
              // console.log("getWXaccess_token data.result", result.data.data)
              for (let key in result.data.data) {
                store.wx[key] = result.data.data[key]
              }

              localStorage.setItem("wx", JSON.stringify({openid: result.data.data.openid, appid: store.wx.appid}));
            //   console.log('location.href--', location.href)
            //   console.log('ocation.search--', search)
              let newurl = location.href.replace(new RegExp(search), '')
              let parmstr = Config.resetUrlParam(urlParam, ['code'])
              newurl += parmstr
              history.replaceState(null, null, newurl);  //替换地址栏
            }
          })
        }
      }
      // 2.openid -> userid
      if (store.wx.openid && !store.userId) {
        await MessageProxy.getUserInfo(store.wx.openid).then((result) => {
          if (true == result.hasError) {
            alert(result.errorText);
            return;
          }
          if (result.data != null && result.data.code != '200') {
            alert(result.data.message);
            return;
          }
          if (result.data.data != null) {
            store.userId = result.data.data[0].userId;
            store.phoneNumber = result.data.data[0].mobilePhone;
            store.user.id = result.data.data[0].userId;
            store.user.tel = result.data.data[0].mobilePhone;
          }
        })
      }
    }
    if (Config.isWxClient() && !store.userId) {
      console.log('微信环境userid, 获取失败')
    }
  }
  static async wxPayPage(data) {
    var cureenturl = encodeURIComponent(window.location.href)
    let openid = '&openid=' + store.wx.openid
    let goods_id = '&goods_id=' + data.goods_id
    let goods_name = '&goods_name=' + data.goods_name
    let studyID = '&studyID=' + data.studyID
    let orderMoney = '&orderMoney=' + data.goods_amount
    let userId = '&userId=' + store.userId
    let hospitalId = '&hospitalId=' + data.hospitalId
    let patientName = '&patientName=' + data.patientName
    window.location = window.location.protocol + '//pay.efilm.top/pay.html?returnurl=' + cureenturl + goods_id + openid + goods_name + studyID + orderMoney + userId + hospitalId + patientName
  }
}

export class UrlHelper {
  static parse(argName) {
    let svalue = location.href.match(new RegExp("[?&]" + argName + "=([^&]*)(&?)", "i"));
    return svalue ? svalue[1] : "";
  }

  static get serverUrl() {
    let serverUrl = location.origin + "/";
    if (location.port == "80") {
      serverUrl = location.protocol + "//" + location.hostname + "/";
    } else {
      serverUrl = location.protocol + "//" + location.hostname + ":" + location.port + "/";
    }
    if (Config.serverUrl != undefined && Config.serverUrl != "") {
      if (Config.serverUrl.toLowerCase().indexOf("http") == 0) {
        if (Config.serverUrl.lastIndexOf("/") == Config.serverUrl.length - 1) {
          serverUrl = Config.serverUrl;
        } else {
          serverUrl = Config.serverUrl + "/";
        }
      } else {
        serverUrl += Config.serverUrl + "/";   //为了 Nginx 映射用
      }
    }
    return serverUrl;
  }

  static reqUrl(subPath) {
    if (UrlHelper.serverUrl.endsWith('/') == true && subPath.startsWith("/") == true) {
      return UrlHelper.serverUrl + subPath.substr(1);
    } else if (UrlHelper.serverUrl.endsWith('/') == true && subPath.startsWith("/") == false) {
      return UrlHelper.serverUrl + subPath;
    } else if (UrlHelper.serverUrl.endsWith('/') == false && subPath.startsWith("/") == true) {
      return UrlHelper.serverUrl + subPath;
    } else {
      return UrlHelper.serverUrl + '/' + subPath;
    }
  }
}

export class UUID {
    static random() {
        return (this.S4() + this.S4() + "-" + this.S4() + "-" + this.S4() + "-" + this.S4() + "-" + this.S4() + this.S4() + this.S4());
    }

    static S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
}

export class Utils {
    static isPhone() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        if (null == flag) {
            return false;
        } else {
            return true;
        }
    }
    /*
    Mozilla/5.0 (Windows NT 10.0; Win64; x64)
    AppleWebKit/537.36 (KHTML, like Gecko)
    Chrome/91.0.4472.77
    Safari/537.36
    */
    static isChrome() {
        let regChrome = navigator.userAgent.match(/Chrome/);
        let regMicroMessenger = navigator.userAgent.match(/MicroMessenger/);
        if (regChrome != null && null == regMicroMessenger) {
            return true;
        } else {
            return false;
        }
    }
    /*
    Mozilla/5.0 (Windows NT 10.0; WOW64)
    AppleWebKit/537.36 (KHTML, like Gecko)
    Chrome/53.0.2785.116 Safari/537.36
    QBCore/4.0.1326.400
    QQBrowser/9.0.2524.400
    Mozilla/5.0 (Windows NT 6.1; WOW64)
    AppleWebKit/537.36 (KHTML, like Gecko)
    Chrome/53.0.2875.116 Safari/537.36
    NetType/WIFI
    MicroMessenger/7.0.20.1781(0x6700143B)
    WindowsWechat(0x63010200)
    */
    static isWindowsWechat() {
        let flag = navigator.userAgent.match(/WindowsWechat/);
        if (null == flag) {
            return false;
        } else {
            return true;
        }
    }
    /*
    Mozilla/5.0 (Linux; Android 10; KKG-AN00 Build/HONORKKG-AN00; wv)
    AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0
    Chrome/78.0.3904.62 XWEB/2797 MMWEBSDK/201201 Mobile Safari/537.36 MMWEBID/1206
    MicroMessenger/8.0.1.1841(0x2800015D) Process/toolsmp
    WeChat/arm64
    Weixin NetType/WIFI Language/zh_CN ABI/arm64
    */
    static isWeixin() {
        let regWeChat = navigator.userAgent.match(/WeChat/);
        let regWeixin = navigator.userAgent.match(/Weixin/);
        if (regWeChat != null && regWeixin != null) {
            return true;
        } else {
            return false;
        }
    }

    
    // 生成随机字符串
    static getRandomString(len) {
      const _charStr = 'abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789',
          min = 0, 
          max = _charStr.length - 1
      let _str = '';                    //定义随机字符串 变量
      //判断是否指定长度，否则默认长度为15
      len = len || 15;
      //循环生成字符串
      for(var i = 0, index; i < len; i++){
          index = (function(randomIndexFunc, i){         
              return randomIndexFunc(min, max, i, randomIndexFunc);
          })(function(min, max, i, _self){
              let indexTemp = Math.floor(Math.random()*(max-min+1)+min)
              const numStart = _charStr.length - 10;
              if(i==0&&indexTemp >=numStart){
                  indexTemp = _self(min, max, i, _self);
              }
              return indexTemp ;
          }, i);
          _str += _charStr[index];
      }
      return _str;
  }

}

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423 
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
Date.prototype.Format = function (fmt) { //author: meizz
  var o = {
    "M+": this.getMonth() + 1,                 //月份
    "d+": this.getDate(),                    //日
    "h+": this.getHours(),                   //小时
    "m+": this.getMinutes(),                 //分
    "s+": this.getSeconds(),                 //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    "S": this.getMilliseconds()             //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

Date.prototype.addDays = function (d) {
  this.setDate(this.getDate() + d);
};
Date.prototype.addWeeks = function (w) {
  this.addDays(w * 7);
};
Date.prototype.addMonths = function (m) {
  var d = this.getDate();

  this.setMonth(this.getMonth() + m);
  if (this.getDate() < d){
    this.setDate(0);
  }
};
Date.prototype.addYears = function (y) {
  var m = this.getMonth();
  this.setFullYear(this.getFullYear() + y);
  if (m < this.getMonth()) {
    this.setDate(0);
  }
};
